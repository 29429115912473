import Api from './Api'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`product?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`product/${id}`);
    },
    getTop() {
        return Api().get(`product/top`);
    }
}