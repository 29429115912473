import PaymentService from '../../services/PaymentService'
import OrderService from '@/services/OrderService';

const state = () => ({
    payment: null,
    selectedPayment: false,
    selectedTypePayment: false,
    paymentLoading: false,
    total: 0.00,
    paymentCurrentStep: 1
})
  
const mutations = {
    GO_BACK_TYPES_PAYMENT: (state) => {
        state.selectedPayment = false;
    },
    SET_TYPE_PAYMENT: (state, type) => {
        state.selectedTypePayment = type;
    },
    SET_PAYMENT: (state, {status_payment, type}) => {
        state.payment = status_payment;
        state.selectedPayment = type;
    },
    UPDATE_PAYMENT: (state, payment) => {
        state.payment = payment;
    },
    SET_LOADING_PAYMENT: (state, payload) => {
        state.paymentLoading = payload
    },
    SET_TOTAL: (state, total) => {
        state.total = total.toFixed(2)
    },
    SET_STEP_PAYMENT: (state, stepCurrent) => {
        state.paymentCurrentStep = stepCurrent
    },
}

const actions = {
    async sumCartTotal({commit, rootState}){
        const response = await OrderService.getTotalPayment({products: rootState.product.cart, cupom: rootState.cupom.cupom});
        let totalOrder = Number(response.data.total)
        if(rootState.shipping.minValueShippingFree < rootState.shipping.shippingSelect.price) {
            totalOrder += Number(rootState.shipping.shippingSelect.price);
        }
        commit('SET_TOTAL', totalOrder);
    },
    async createPaymentPix({state, rootState, commit, dispatch}, {cpf, OrderId}){
        try {
            commit('SET_LOADING_PAYMENT', true);
            await dispatch('sumCartTotal')

            const totalCompra = Number(state.total)
            const user = rootState.user.user;
            var CuponId = null;
            if(rootState.cupom && rootState.cupom.cupom && rootState.cupom.cupom.id) {
                CuponId = rootState.cupom.cupom.id
            }
            const body = {
                "transaction_amount": totalCompra,
                "description": process.env.VUE_APP_TITLE,
                "payment_method_id": "pix",
                "payer": {
                    "first_name": user.firstName,
                    "last_name": user.last_name,
                    "email": user.email,
                    "identification": {
                        "type": "CPF",
                        "number": cpf
                    }
                },
                "external_reference": `extRef_${process.env.VUE_APP_TITLE}_${OrderId}`,
                "notification_url": `${process.env.VUE_APP_NOTIFICATION_URL}`
            }
            const response = await PaymentService.createPaymentPix(body);

            const responseMP = response.data.body
            
            PaymentService.savePaymentPix({
                transaction_id: responseMP.id,
                external_reference: responseMP.external_reference,
                status: responseMP.status,
                description: responseMP.description,
                installments: responseMP.installments,
                amount:  responseMP.transaction_amount,
                identificationNumber: cpf.replace(/\D/g, ''),
                cardholderEmail: user.email,
                paymentMethodId: 'pix',
                OrderId,
                CuponId
            });

            //  Provavelmente nunca entrará neste if, pois o pagamento do pix sempre vem como pendent
            if(responseMP.status == 'approved'){
                dispatch('confirmarPagamento', OrderId)
            }

            commit('SET_PAYMENT', { status_payment: responseMP, type:'pix'});
            commit('SET_LOADING_PAYMENT', false);

        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createPaymentCard({rootState, commit, dispatch}, body){
        try {
            commit('SET_LOADING_PAYMENT', true);
            const user = rootState.user.user;
            var CuponId = null;
            if(rootState.cupom && rootState.cupom.cupom && rootState.cupom.cupom.id) {
                CuponId = rootState.cupom.cupom.id
            }
            body.external_reference = `extRef_${process.env.VUE_APP_TITLE}_${body.OrderId}`
            body.cardholderEmail = user.email;
            body.description = process.env.VUE_APP_TITLE;
            body.notification_url = process.env.VUE_APP_NOTIFICATION_URL;

            const response = await PaymentService.createPaymentCard(body);
            
            await PaymentService.savePaymentCredit({orderPayment: body, statusPayment: response.data, CuponId});
          
            if(response.data.status == 'approved'){
                dispatch('confirmarPagamento', body.OrderId)
            }

            commit('SET_PAYMENT', { status_payment: response.data, type:'credito' });
            commit('SET_LOADING_PAYMENT', false);

        } catch (error) {
            commit('SET_LOADING_PAYMENT', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchVerifyPayment({ commit }, transaction_id){
        try {
            const response  = await PaymentService.verifyPayment(transaction_id);
            const { payment } = response.data;

            if(payment.status == 'approved'){
                commit('SET_PAYMENT', { status_payment: payment, type:'pix' });
            }
        } catch (error) {
            console.log(error)
        }
    },
}

const getters = {
    getPayment: state => state.payment,
    getLoadingPayment: state => state.paymentLoading,
    getSelectedPayment: state => state.selectedPayment,
    getSelectedTypePayment: state => state.selectedTypePayment,
    getTotal: state => state.total,
    getPaymentCurrentStep: state => state.paymentCurrentStep
}

export default {
    state,
    getters,
    mutations,
    actions
};