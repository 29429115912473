import Api from './Api'

export default {
    createPaymentPix(data){
        return Api().post(`${process.env.VUE_APP_URL_MP_SERVER}/process_payment_pix`, data);
    },
    createPaymentCard(data){
        return Api().post(`${process.env.VUE_APP_URL_MP_SERVER}/process_payment`, data);
    },
    verifyPayment(transaction_id){
        return Api().post(`${process.env.VUE_APP_URL_MP_SERVER}/verifyPayment`, { transaction_id });
    },
    savePaymentCredit(data){
        return Api().post('payment/add/credit', data);
    },
    savePaymentPix(data){
        return Api().post('payment/add/pix', data);
    },
}