import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import product from './modules/product';
import review from './modules/review';
import order from './modules/order';
import address from './modules/address';
import payment from './modules/payment';
import shipping from './modules/shipping';
import password from './modules/password';
import email from './modules/email';
import cupom from './modules/cupom';

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        product,
        review,
        order,
        address,
        email,
        payment,
        shipping,
        password,
        email,
        cupom
    }
})
