import CupomService from '../../services/CupomService'

const state = () => ({
  cupom: (() => {
    try {
        return JSON.parse(localStorage.getItem('cupom')) || null;
    } catch (error) {
        localStorage.removeItem('cupom');
        return null;
    }
  })(),
  loadingCupom: false
})

const mutations = {
    SET_CUPOM: (state, objCupom) => {
      localStorage.setItem('cupom', JSON.stringify(objCupom));
      state.cupom = objCupom;
    },
    SET_LOADING_CUPOM: (state, payload) => {
      state.loadingCupom = payload
  },
}
  
const actions = {
  async setCuponCode({commit}, cuponCode) {
    try{
      commit('SET_LOADING_CUPOM', true);
      const response = await CupomService.get(cuponCode);

      if(response.data.cupom){
        commit('SET_CUPOM', response.data.cupom)
        commit('SET_ALERT', {
            heading: 'success',
            message: response.data.message
        });
      }
      commit('SET_LOADING_CUPOM', false);
    } catch (error) {
      commit('SET_LOADING_CUPOM', false);
      commit('SET_ALERT', {
          heading: 'error',
          message: error.response.data.message
      });
    }
  },
}

const getters = {
  getCupomLoading: state => state.loadingCupom,
  getCupom: state => state.cupom,
}

export default {
  state,
  getters,
  mutations,
  actions
};