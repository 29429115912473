<template>
    <div class="animate-pulse shadow-sm rounded-md max-w-sm">               
        <div class="w-full bg-gray-300 h-60 md:h-96"></div>
        <div class="flex flex-col mt-1 space-y-1 h-8  justify-center">
            <div class="w-full bg-gray-300 h-4"></div>
            <div class="w-full max-w-[6em] bg-gray-300 h-3"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SingleProductSkeleton'
    }
</script>

<style lang="scss" scoped>

</style>