import Api from './Api'

export default {
    get() {
        return Api().get(`order/myorders`);
    },
    post(payload) {
        return Api().post(`order/add`, payload);
    },
    confirm(orderId) {
        return Api().post(`order/confirm-payment/${orderId}`);
    },
    getTotalPayment(data){
        return Api().post('order/getTotal', data);
    },
}